

































































































































































































































































































































































































import {
    IInfringement, IInfringementResponse, IInfringementsResponse,
} from '@/interfaces/infringement';
import Base from '@/mixins/Base.vue';
import { IJob } from '@/interfaces/job';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        job: {
            type: Object as () => IJob,
            default: null,
        },

    },
    mounted() {
        this.getJobInfringements();
    },
    data() {
        return {
            isAddJobInfringementModalActive: false,
            params: {
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
            },
            infringements: {
                data: [] as Array<IInfringement>,
                meta: {} as IPagination | any,
            } as IInfringementsResponse,
            newInfringement: {} as (any | IInfringement),
        };
    },
    methods: {
        getJobInfringements(page: number = 1) {
            this.params.page = page;

            this.get<IInfringementsResponse>(`jobs/${this.$route.params.id}/infringements`, {
                params: {
                    ...this.params,
                },
            }).then(({ data }) => {
                this.infringements = data;
            });
        },

        saveJobInfringement() {
            this.post<IInfringementResponse>(`jobs/${this.$route.params.id}/infringements`, {
                ...this.newInfringement,
                job_id: this.job.id,
                object_id: this.newInfringement.object ? this.newInfringement.object.id : null,
            }).then(() => {
                this.getJobInfringements();
                this.isAddJobInfringementModalActive = false;
            });
        },

        deleteJobInfringement(infringement: IInfringement) {
            this.confirm().then(() => {
                this.delete(`infringements/${infringement.id}`).then(() => {
                    this.getJobInfringements();
                });
            });
        },
    },
});

export default component;
