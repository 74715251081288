






























































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';

import { IJob, IJobResponse } from '@/interfaces/job';
import { IBilling } from '@/interfaces/billing';
import services from './partials/Services.vue';
import infringements from './partials/Infringements.vue';

const component = Base.extend({
    beforeRouteUpdate(to: any, from: any, next: Function) {
        this.getJob(to.params.id);
        next();
    },
    components: {
        services,
        infringements,
    },
    data() {
        return {
            activeTab: 'general',
            activeSubTab: 'inspection',
            job: undefined as any | IJob,
        };
    },
    mounted() {
        this.getJob(parseInt(this.$route.params.id, 0));
    },
    methods: {
        getJob(id: number): void {
            this.get<IJobResponse>(`jobs/${id}`).then(({ data }) => {
                this.job = data.data;
                this.job.inspection_date = new Date(this.job.inspection_date);

                this.activeTab = 'general';
                this.activeSubTab = 'inspection';
            });
        },
        saveJob(): void {
            this.put<IJobResponse>(`jobs/${this.$route.params.id}`, {
                ...this.job,
                billings: this.job.billings.map((billing: IBilling) => ({
                    id: billing.id,
                    job_id: billing.job_id,
                    type: billing.type,
                    name: billing.name,
                    email: billing.email,
                    address: billing.address,
                    vat: billing.vat,
                })),

            })
                .then(({ data }) => {
                    this.job = data.data;
                    this.job.inspection_date = new Date(this.job.inspection_date);
                    this.$emit('success', { message: `job ${data.data.code} updated` });
                });
        },

    },
});

export default component;
