
























































































































































































































import { IJob } from '@/interfaces/job';
import { ISpObject } from '@/interfaces/spobject';
import { ITask } from '@/interfaces/task';
import Base from '@/mixins/Base.vue';

const component = Base.extend({
    props: {
        job: {
            type: Object as () => IJob,
            default: null,
        },
    },
    data() {
        return {

        };
    },
    methods: {
        removeObjectFromService(p: ITask, index: number): void{
            this.$delete(p.objects, index);
        },
        createObjectForService(p: ITask): void{
            const service: ITask = p;
            const newObject: ISpObject = {
                name: 'New object',
                client_reference_1: '',
                client_reference_2: '',
                description: '',
                serial: '',

            } as ISpObject;

            if (!service.objects) service.objects = [] as Array<ISpObject>;
            service.objects.push(newObject);
        },
    },
});

export default component;
